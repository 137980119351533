
import { commonProps } from '../../utils/prismic';
import { normalizeFormData } from '../../utils/helpers/normalize-form-data.js';

export default {
  components: {
    FormBlock: () => import('../form-block'),
    ConfirmationBlock: () => import('../confirmation-block'),
    Comparadise: () => import('../comparadise'),
  },
  props: commonProps,
  data() {
    return {
      formData: null,
      confirmationScreen: false,
    };
  },
  computed: {
    sliceData() {
      return this.slice.data;
    },
    iframeData() {
      if (process.client) {
        const urlParams = new URLSearchParams(window.location.search);
        const rcd_gclid = urlParams.get('gclid');
        const url = new URL(this.sliceData.iframe?.iframeUrl);

        if (rcd_gclid) url.searchParams.append('gclid', rcd_gclid);
        const iframeUrl = url.toString();

        return { ...this.sliceData.iframe, iframeUrl };
      }
      return null;
    },
  },

  methods: {
    handleFormSuccess(res) {
      if (res.formData) {
        this.formData = normalizeFormData(res.formData, res.formType);
      }

      this.confirmationScreen = true;
    },
  },
};
