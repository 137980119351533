const formTypesFields = {
  merlin: {
    Adh1Civilite: 'civ',
    Adh1Prenom: 'prenom',
    Adh1Nom: 'nom',
    Adh1Naiss: 'date_naissance',
    Adh1CP: 'cp',
    Adh1TelMob: 'tel',
    Adh1Mail: 'email',
    DateEffet: 'date_effet',
    MontantPret1: 'montant',
    TauxPret1: 'taux',
    DureePret1: 'duree',
    Fumeur: 'fumeur',
  },
  marketplace: {
    civility: 'civ',
    name: 'prenom',
    surname: 'nom',
    birthDate: 'date_naissance',
    zipCode: 'cp',
    phone: 'tel',
    email: 'email',
    effectiveDate: 'date_effet',
    euroAmount: 'montant',
    rate: 'taux',
    duration: 'duree',
    smokingType: 'fumeur',
  },
  gamer: {
    civiliteprospect: 'civ',
    prenomprospect: 'prenom',
    nomprospect: 'nom',
    cpprospect: 'cp',
    telprospect: 'tel',
    emailprospect: 'email',
  },
  mailto: {
    civility: 'civ',
    firstname: 'prenom',
    name: 'nom',
    phone: 'tel',
    email: 'email',
  },
  azzur: {
    civility: 'civ',
    firstname: 'prenom',
    name: 'nom',
    phone: 'tel',
    email: 'email',
    rcd_deja_assure: 'rcd_deja_assure',
  },
  pipedrive: {
    civility: 'civ',
    firstname: 'prenom',
    name: 'nom',
    phone: 'tel',
    email: 'email',
  },
};

const smokingTypesValues = {
  merlin: {
    O: 'o',
    N: 'n',
  },
  marketplace: {
    SMOKING: 'o',
    NOT_SMOKING: 'n',
  },
};

const formFieldValueMap = (name, value, formType) => {
  switch (name) {
    case 'civ':
      return value.toLowerCase().replace(/\./g, '');
    case 'date_effet':
    case 'date_naissance':
      return value.replace(/\//g, '-');
    case 'fumeur':
      return smokingTypesValues[formType][value];
    default:
      return value;
  }
};

export const normalizeFormData = (data, formType) => {
  const normalizedInputs = {};

  if (!formTypesFields[formType]) {
    return normalizedInputs;
  }

  for (let input of data.entries()) {
    const [name, value] = input;
    const normalizedName = formTypesFields[formType][name];
    if (normalizedName) {
      normalizedInputs[normalizedName] = formFieldValueMap(
        normalizedName,
        value,
        formType
      );
    }
  }

  return normalizedInputs;
};
